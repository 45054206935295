<template>
  <div class="page row">
    <div v-show="clickedLayer" class="col-lg-4">
      <h3 class="font-weight-bold">Boundary editor</h3>
      <v-card v-if="clickedUneditableField">
        <v-card-title class="d-flex justify-content-left">
          <span class="text-h6">Field Information</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <span class="text-h6 red--text">This field, {{ selectedUneditableFieldName }} is uneditable
                  due to the following reason(s):</span>
                <div class="error-message ml-5 p-1" v-for="message in uneditableFieldMessage" :key="message">
                  <li>{{ message }}</li>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card v-else-if="clickedEditFieldNameOption">
        <v-card class="mb-3">
          <v-card-title class="d-flex justify-content-left">
            <span class="text-h6" @click="showInfoSection = !showInfoSection">{{
              tabName
              }}</span>
            <v-icon @click="showInfoSection = !showInfoSection">
              {{ showInfoSection ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-card-title>

          <div v-show="showInfoSection">
            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">Organization</span>
                <input type="text" class="form-control" disabled v-model.trim="selectedOrgName" />
              </div>
            </div>

            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">Client</span>
                <input type="text" class="form-control" disabled v-model.trim="selectedClient.name" />
              </div>
            </div>

            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">Farm</span>
                <input type="text" class="form-control" disabled v-model.trim="selectedFarm.name" />
              </div>
            </div>

            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">{{ fieldLabel }}</span>
                <div class="input-group">
                  <input type="text" class="form-control" :disabled="disableFieldInfo" v-model.trim="fieldName" />
                </div>
              </div>
            </div>

            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">Total Area (In {{ selectedUnits }})</span>
                <div class="input-group">
                  <input type="text" class="form-control" readonly v-model.trim="totalAreaBySelectedUnit" />
                </div>
              </div>
            </div>
          </div>
        </v-card>

        <v-card>
          <v-card-title class="d-flex justify-content-left">
            <span class="text-h6" @click="showEditSection = !showEditSection">{{
              editFieldLabel
              }}</span>
            <v-icon @click="showEditSection = !showEditSection">
              {{ showEditSection ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-card-title>

          <div v-show="showEditSection">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="newFieldName" :label="isRanchForce ? 'New Pasture Name' : 'New Field Name'
                      " :rules="newFieldNameRules"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="resetLeftPanel">
                Cancel
              </v-btn>
              <v-btn color="blue-darken-1" variant="text" :disabled="!newFieldName" @click="editFieldName">
                Save
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-card>

      <v-card v-else>
        <v-toolbar flat>
          <v-tabs grow v-model="tab" active-class="active-tab font-weight-bold">
            <v-tabs-slider color="white"></v-tabs-slider>
            <v-tab v-for="item in tabTitles" :key="item.index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabTitles" :key="item.index">
            <v-card flat>
              <div v-if="item.name === 'Create'" class="py-3">
                <span><v-chip class="ml-5" color="primary">
                    Creating new boundary from {{ numPolygons }} drawn
                    polygon(s)
                  </v-chip></span>
                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-select v-model="selectedOrg" :items="organizationOptions" :disabled="disableFieldInfo"
                      item-text="name" item-value="id" label="Organization"></v-select>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-combobox v-model="selectedClient" dense outlined label="Client" :items="clientOptions"
                      :disabled="disableFieldInfo" @input="handleInputForClient" @blur="handleBlurForClient"
                      item-text="name" item-value="id">
                    </v-combobox>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-combobox dense outlined :label="isRanchForce ? 'Ranch' : 'Farm'" :items="farmOptions"
                      v-model="selectedFarm" :disabled="disableFieldInfo" @input="handleInputForFarm"
                      @blur="handleBlurForFarm" item-text="name" item-value="id">
                    </v-combobox>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title"> {{ fieldLabel }}</span>
                    <div class="input-group">
                      <input type="text" class="form-control" :disabled="disableFieldInfo" v-model.trim="fieldName" />
                    </div>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title">Total Area (In {{ selectedUnits }})</span>
                    <div class="input-group">
                      <input type="text" class="form-control" readonly v-model.trim="totalAreaBySelectedUnit" />
                    </div>
                  </div>
                </div>

                <div v-if="showClearChangesBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn small plain color="red" @click="confirmBeforeClearingChanges = true">
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Clear changes
                    </v-btn>
                  </div>
                </div>

                <div v-if="showDeleteBoundaryBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn small plain color="red" @click="confirmBeforeDeletingBoundary = true">
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Delete boundary
                    </v-btn>
                  </div>
                </div>
              </div>

              <div v-if="item.name === 'Edit'" class="py-3">
                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-select v-model="selectedOrg" :items="organizationOptions" :disabled="disableFieldInfo"
                      item-text="name" item-value="id" label="Organization"></v-select>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-combobox v-model="selectedClient" dense outlined label="Client" :items="clientOptions"
                      :disabled="disableFieldInfo" @input="handleInputForClient" @blur="handleBlurForClient"
                      item-text="name" item-value="id">
                    </v-combobox>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-combobox dense outlined :label="isRanchForce ? 'Range' : 'Farm'" :items="farmOptions"
                      v-model="selectedFarm" :disabled="disableFieldInfo" @input="handleInputForFarm"
                      @blur="handleBlurForFarm" item-text="name" item-value="id">
                    </v-combobox>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title"> {{ fieldLabel }}</span>
                    <div class="input-group">
                      <input type="text" class="form-control" :disabled="disableFieldInfo" v-model.trim="fieldName" />
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" @click="openEditFieldName" class="ml-2">
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Click to edit</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title">Total Area (In {{ selectedUnits }})</span>
                    <div class="input-group">
                      <input type="text" class="form-control" readonly v-model.trim="totalAreaBySelectedUnit" />
                    </div>
                  </div>
                </div>

                <div v-if="showClearChangesBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn small plain color="red" @click="confirmBeforeClearingChanges = true">
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Clear changes
                    </v-btn>
                  </div>
                </div>

                <div v-if="showDeleteBoundaryBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn small plain color="red" @click="confirmBeforeDeletingBoundary = true">
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Delete boundary
                    </v-btn>
                  </div>
                </div>
              </div>

              <!-- <div v-if="item.name === 'Subdivide'" class="pt-3">
                <div class="ml-2">
                  <span class="text-h6 red--text"
                    >Under Construction, more features are coming soon!</span
                  >
                </div>
              </div> -->

              <div v-if="item.name === 'Subdivide'" class="py-3 tab-header">
                <v-toolbar flat>
                  <v-tabs grow v-model="tabPaddocks" active-class="active-tab font-weight-bold">
                    <v-tabs-slider color="white"></v-tabs-slider>
                    <v-tab v-for="itemPaddocks in tabTitlesPaddocks" :key="itemPaddocks.index">
                      {{ itemPaddocks.name }}
                    </v-tab>
                  </v-tabs>
                </v-toolbar>
                <v-tabs-items v-model="tabPaddocks">
                  <v-tab-item v-for="itemPaddocks in tabTitlesPaddocks" :key="itemPaddocks.index">
                    <v-card flat>
                      <div v-if="itemPaddocks.name === 'Create'" class="py-3">
                        <span v-if="isCreatingSubDivide"><v-chip class="ml-5" color="primary">
                            Draw your paddock polygon and then save
                          </v-chip></span>
                        <span v-else><v-chip class="ml-5" color="primary">
                            Click create paddock button to start subdivision
                            process
                          </v-chip></span>
                        <div class="row ml-3 pt-3">
                          <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                            <v-btn :class="['mb-2', { active: isCreatingSubDivide }]" @click="handleCreatePaddock">
                              Create Paddock
                            </v-btn>
                            <!-- <v-btn :class="{ active: isCreatingSubDivide }" @click="handleCreatePaddock">
                              Create Paddock via Draw Line
                            </v-btn> -->
                          </div>
                        </div>

                        <div class="row ml-3">
                          <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                            <!-- <span class="default-input-title">Paddock Name</span> -->
                            <!-- <div class="input-group">
                              <input type="text" class="form-control" v-model.trim="paddockName" />
                            </div> -->
                            <div v-for="(newPaddock, key) in recentlyCreatedPaddocks" :key="key">
                              <label>Paddock {{key}}</label>
                              <input 
                              :id="'paddock-' + key"
                              type="text" class="form-control"
                              placeholder="Paddock Name"
                              v-model="recentlyCreatedPaddocks[key]"  
                              @input="handlePaddockChange(key, recentlyCreatedPaddocks[key])"
                            />
                            </div>
                          </div>
                        </div>

                        <div class="row ml-3">
                          <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                            <span class="default-input-title">Total Area (In {{ selectedUnits }})</span>
                            <div class="input-group">
                              <input type="text" class="form-control" readonly v-model.trim="totalAreaBySelectedUnit" />
                            </div>
                          </div>
                        </div>

                        <div v-if="showClearChangesBtn" class="row ml-1">
                          <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                            <v-btn small plain color="red" @click="confirmBeforeClearingChanges = true">
                              <v-icon>mdi-trash-can-outline</v-icon>
                              Clear changes
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div v-if="itemPaddocks.name === 'Edit'" class="py-3">
                        <div class="row ml-3" v-if="clickedEditPaddockNameOption">
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field v-model="newPaddockName" label="New Paddock Name"
                                    :rules="newPaddockNameRules"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue-darken-1" variant="text" @click="resetLeftPanel">
                              Cancel
                            </v-btn>
                            <v-btn color="blue-darken-1" variant="text" :disabled="!newPaddockName"
                              @click="editPaddockName">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </div>
                        <div class="row ml-3" v-else>
                          <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                            <span class="default-input-title">Paddocks</span>
                            <div class="input-group">
                              <input type="text" class="form-control" :disabled="disableFieldInfo"
                                v-model.trim="paddockName" />
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on" @click="openEditPaddockName" class="ml-2">
                                    <v-icon small>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span>Click to edit</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                        <div v-if="true" class="row ml-1">
                          <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                            <v-btn small plain color="red" @click="confirmBeforeDeletingBoundary = true">
                              <v-icon>mdi-trash-can-outline</v-icon>
                              Delete Paddocks
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>

                <div v-if="showDeleteBoundaryBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn small plain color="red" @click="confirmBeforeDeletingBoundary = true">
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Delete boundary
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <div v-show="!clickedEditFieldNameOption" class="row footer-row justify-content-end">
        <div class="col-lg-11 btn-row d-flex justify-content-end">
          <v-btn class="ma-1" color="grey" @click="cancelChanges">
            Cancel
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-1" color="grey" v-bind="attrs" v-on="on" @click="closeLeftPanel">
                Hide
              </v-btn>
            </template>
            <span>Close the left panel</span>
          </v-tooltip>

          <v-btn :disabled="disableUploadChangesBtn" class="ma-1" color="success" @click="confirmChanges">
            Save Changes
          </v-btn>
        </div>
      </div>
    </div>

    <v-snackbar top :class="clickedLayer ? 'snack-bar-half-map' : 'snack-bar-full-map'" :color="color"
      :timeout="timeout" v-model="showSnack">
      <span v-if="isSnackTextArray">
        {{ errorMessage }}
        <ul>
          <li v-for="text in snackText" :key="text">
            {{ text }}
          </li>
        </ul>
      </span>
      <span v-else>
        {{ snackText }}
      </span>
    </v-snackbar>

    <div id="mapContainer" class="col map-wrapper">
      <Tooltip @menu-option-clicked="handleMenuOptionClicked" />
    </div>

    <ConfirmModal v-if="confirmBeforeDeletingBoundary" 
      :titleText="`Confirm Delete ${clickedLayer.type === 'field' ? 'Boundary' : 'Paddock'}`"
      :confirmText="`You're about to delete a field ${clickedLayer.type === 'field' ? 'boundary' : 'paddock'} with layers of data associated with it. Are you sure you'd like to continue?`"
      @confirm="
        clickedLayer.isNewBoundary ? deleteUnsavedBoundary() : deleteBoundary()
        " @close-modal="confirmBeforeDeletingBoundary = false" />

    <ConfirmModal v-if="confirmBeforeClearingChanges" titleText="Confirm Clear Changes"
      confirmText="You're about to clear all changes made to this boundary. Are you sure you'd like to continue?"
      @confirm="clearUnsavedBoundary" @close-modal="confirmBeforeClearingChanges = false" />

    <ConfirmModal v-if="confirmClearAllChanges" titleText="Confirm Clear All Unsaved Changes"
      confirmText="You're about to discard all unsaved changes on the map. This action is irreversible. Are you sure you want to continue?"
      @confirm="clearAllChanges" @close-modal="confirmClearAllChanges = false" />
  </div>
</template>
<script>
import polygonSplitter from "polygon-splitter";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import * as turf from "@turf/turf";
import "leaflet-boundary-canvas";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder";
import "leaflet-editable";
import { multiPolygon, point, polygon } from "@turf/helpers";
import turfArea from "@turf/area";
import buffer from "@turf/buffer";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import booleanOverlap from "@turf/boolean-overlap";
import booleanIntersects from "@turf/boolean-intersects";
import bbox from "@turf/bbox";
import booleanEqual from "@turf/boolean-equal";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Filter, Map, Uploads } from "@/store/modules";
import { MAPBOX_TOKEN } from "@/constants/map";
import { IS_RANCHFORCE } from "@/constants";
import Tooltip from "@/components/map/Tooltip";
import moment from "moment";
import shpWrite from "@mapbox/shp-write";
// import FieldsAPI from "@/api/FieldsAPI"
import PasturesAPI from "@/api/PasturesAPI";
import PaddocksAPI from "../api/PaddocksAPI";

export default {
  name: "BoundaryEditorRanchView",
  components: {
    Tooltip,
    ConfirmModal,
  },

  data() {
    return {
      alertShown: false,
      map: null,
      drawnFeatures: null,
      clickedLayer: null,
      isClickedLayerEqualOrgPolygon: null,
      legend: null,
      componentKey: 0,
      totalAreaBySelectedUnit: null,
      noDataAvailableText: "No Data Available",
      selectedOrg: null,
      selectedClient: null,
      selectedFarm: null,
      fieldName: null,
      numPolygons: 0,
      mapLayerTracker: {},
      disableFieldInfo: false,
      confirmBeforeDeletingBoundary: false,
      confirmBeforeClearingChanges: false,
      color: "blue",
      timeout: -1,
      showSnack: false,
      snackText: null,
      errorMessage: null,
      mapViewOption: "satellite",
      clickedEditFieldNameOption: false,
      clickedEditPaddockNameOption: false,
      tab: 0,
      tabPaddocks: 0,
      tabTitles: [
        {
          index: 0,
          name: "Create",
        },
        {
          index: 1,
          name: "Edit",
        },
        {
          index: 2,
          name: "Subdivide",
        },
      ],
      tabTitlesPaddocks: [
        {
          index: 0,
          name: "Create",
        },
        {
          index: 1,
          name: "Edit",
        },
      ],
      newFieldName: null,
      newPaddockName: null,
      showInfoSection: false,
      showEditSection: false,
      newFieldNameRules: [
        (v) => !!v || "Pasture name is required",
        (v) =>
          (v && v.length <= 255) ||
          "Pasture name must be less than 255 characters",
      ],
      newPaddockNameRules: [
        (v) => !!v || "Paddock name is required",
        (v) =>
          (v && v.length <= 255) ||
          "Paddock name must be less than 255 characters",
      ],
      isRanchForce: IS_RANCHFORCE,
      selectedUnits: "acres",
      uneditableFields: {},
      clickedUneditableField: false,
      uneditableFieldMessage: "",
      selectedUneditableFieldName: "",
      confirmClearAllChanges: false,
      isCreatingSubDivide: false,
      paddockName: "",
      parentPasture: null,
      parentOrgNodeId: null,
      recentlyCreatedPaddocks: {},
      disablePaddockUploadChangesBtn: true,
    };
  },

  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
      allFields: Filter.Getters.getFields,
    }),

    ...mapState({
      organizationOptions: (state) => state.Filter.orgNodes,
      clients: (state) => state.Filter.clients,
      farms: (state) => state.Filter.farms,
      boundaries: (state) => state.Map.fieldBoundaries,
      //selectedUnits: state => state.Filter.selectedUnits,
      rightClickData: (state) => state.Map.rightClickData,
      //isRanchForce: state => state.User.user.application === "rangeforce",
    }),

    isSnackTextArray() {
      return Array.isArray(this.snackText);
    },

    tabName() {
      return this.isRanchForce ? "Pasture Information" : "Field Information";
    },

    fieldLabel() {
      return this.isRanchForce ? "Pasture" : "Field";
    },

    editFieldLabel() {
      return this.isRanchForce ? "Edit Pasture Name" : "Edit Field Name";
    },

    clientOptions() {
      if (this.selectedOrg)
        return this.clients.filter(
          (client) => client.orgNodeId === this.selectedOrg
        );
      return this.clients;
    },

    farmOptions() {
      if (this.selectedClient)
        return this.farms.filter(
          (farm) => farm.clientId === this.selectedClient.id
        );
      return this.farms;
    },

    disableUploadChangesBtn() {
      if (this.isCreatingSubDivide) {
        return this.disablePaddockUploadChangesBtn
      } else {
        if (this.clickedLayer && this.clickedLayer.type == 'paddock') {
          return this.isClickedLayerEqualOrgPolygon
        } else {
          return (
            !this.selectedOrg ||
            !this.selectedClient ||
            !this.selectedFarm ||
            !this.fieldName ||
            this.isClickedLayerEqualOrgPolygon
          );
        }
      }
    },

    fields() {
      const selectedFieldIds = this.selectedFields.map((field) => field.id);
      const filteredBounds =
        selectedFieldIds.length > 0
          ? this.boundaries.filter((row) =>
            selectedFieldIds.includes(row.properties.field.id)
          )
          : this.boundaries;
      return filteredBounds;
    },

    bounds() {
      const geometry = {
        type: "MultiPolygon",
        coordinates: [],
      };
      for (const field of this.fields) {
        geometry.coordinates = geometry.coordinates.concat(
          field.geometry.coordinates
        );
      }
      return geometry;
    },

    showDeleteBoundaryBtn() {
      if (this.clickedLayer)
        return (
          this.clickedLayer.isNewBoundary && !this.isClickedLayerEqualOrgPolygon
        );
      return false;
    },

    showClearChangesBtn() {
      if (this.clickedLayer)
        return (
          !this.clickedLayer.isNewBoundary && !this.disableUploadChangesBtn
        );
      return false;
    },

    selectedOrgName() {
      if (this.selectedOrg)
        return this.organizationOptions.find(
          (org) => org.id === this.selectedOrg
        ).name;
      return null;
    },
  },

  watch: {
    bounds() {
      this.updateMap();

      if (this.lastMapAction === "click") {
        this.lastMapAction = null;
        return;
      }
      this.zoomToBounds();
    },

    boundaries() {
      this.getBoundLayers();
    },

    clickedLayer() {
      if (this.isCreatingSubDivide) {
        this.tab = 2;
        return;
      }
      if (this.clickedLayer) {
        this.disableFieldInfo = !this.clickedLayer.isNewBoundary;
        if (this.clickedLayer.isNewBoundary) this.tab = 0;
        else if (this.clickedLayer.type === "paddock") {
          this.tab = 2;
          this.tabPaddocks = 1;
        } else this.tab = 1;
      }
    },

    selectedOrg() {
      if (this.clickedLayer) {
        this.clickedLayer.toGeoJSON().properties.field.farm.org_node_id =
          this.selectedOrg;
        for (let featureKey in this.map.features) {
          this.map.features[featureKey].properties.field.farm.org_node_id =
            this.selectedOrg;
        }
      }
    },

    fieldName() {
      if (this.clickedLayer && this.clickedLayer.isNewBoundary) {
        this.clickedLayer.toGeoJSON().properties.field.name = this.fieldName;
        for (let featureKey in this.map.features) {
          this.map.features[featureKey].properties.field.name = this.fieldName;
        }
      }
    },

    paddockName() {
      if (this.clickedLayer && this.clickedLayer.isNewBoundary) {
        this.clickedLayer.toGeoJSON().properties.paddock.name =
          this.paddockName;
        for (let featureKey in this.map.features) {
          this.map.features[featureKey].properties.paddock.name =
            this.paddockName;
        }
      }
    },

    selectedUnits() {
      if (this.totalAreaBySelectedUnit)
        this.totalAreaBySelectedUnit = this.convertAreaToSelectedUnit(
          this.totalAreaBySelectedUnit
        );
    },
  },

  methods: {
    ...mapActions({
      updateRanchBoundary: Uploads.Actions.updateRanchBoundary,
      updateRanchPaddocks: Uploads.Actions.updateRanchPaddocks,
    }),

    ...mapMutations({
      setHoverData: Map.Mutations.setHoverData,
      setRightClickData: Map.Mutations.setRightClickData,
      toggleField: Filter.Mutations.toggleItem,
    }),

    fetchUneditableFields() {
      PasturesAPI.fetchUneditableFields().then((response) => {
        this.uneditableFields = response.data;
      });
    },

    fillFunction(d) {
      if (this.fields.find((field) => field.geometry === d.geometry)) {
        return true;
      }
      return false;
    },

    boundsStyle(feature) {
      return {
        color: "#FFCC00",
        weight: 1,
        opacity: 0.85,
        fill: this.fillFunction(feature),
      };
    },

    paddocksBoundsStyle(feature) {
      return {
        color: "#FFF",
        weight: 1,
        opacity: 0.85,
        fill: this.fillFunction(feature),
      };
    },

    openEditFieldName() {
      this.clickedEditFieldNameOption = true;
      this.showEditSection = true;
    },

    openEditPaddockName() {
      this.clickedEditPaddockNameOption = true;
    },

    getBoundLayerByFieldId(fieldId) {
      for (let boundsLayerIdx in this.boundaries) {
        if (fieldId === this.boundaries[boundsLayerIdx].properties.field.id) {
          const multiPolygon = this.boundaries[boundsLayerIdx];
          multiPolygon.geometry.coordinates.forEach((coordinates) => {
            const polygonLayer = this.multiPolygonToPolygon(
              coordinates,
              multiPolygon.centroid,
              multiPolygon.properties
            );
            new L.GeoJSON(polygonLayer, {
              style: this.boundsStyle,
              onEachFeature: (feature, layer) => {
                layer.id = L.stamp(layer);
                layer.isNewBoundary = false;
                this.drawnFeatures.addLayer(layer);
              },
            });

            return;
          });
        }
      }
    },

    getBoundLayers() {
      for (let boundsLayerIdx in this.boundaries) {
        const fieldMultiPolygon = this.boundaries[boundsLayerIdx];
        const paddocksBoundaries = fieldMultiPolygon.properties.field.paddocks;
        fieldMultiPolygon.geometry.coordinates.forEach((coordinates) => {
          const polygonLayer = this.multiPolygonToPolygon(
            coordinates,
            fieldMultiPolygon.centroid,
            fieldMultiPolygon.properties
          );
          new L.GeoJSON(polygonLayer, {
            style: this.boundsStyle,
            onEachFeature: (feature, layer) => {
              layer.id = L.stamp(layer);
              layer.isNewBoundary = false;
              layer.type = "field";
              this.drawnFeatures.addLayer(layer);
            },
          });
        });

        if (paddocksBoundaries) {
          for (let paddocksId in paddocksBoundaries) {
            const paddocksMultiPolygon = paddocksBoundaries[paddocksId];

            paddocksMultiPolygon.geometry.coordinates.forEach((coordinates) => {
              const polygonLayer = this.multiPolygonToPolygon(
                coordinates,
                paddocksMultiPolygon.centroid,
                paddocksMultiPolygon.properties
              );
              new L.GeoJSON(polygonLayer, {
                style: this.paddocksBoundsStyle,
                onEachFeature: (feature, layer) => {
                  layer.id = L.stamp(layer);
                  layer.isNewBoundary = false;
                  layer.type = "paddock";
                  this.drawnFeatures.addLayer(layer);
                },
              });
            });
          }
        }
      }
    },

    getOsmLayer() {
      let osmLayer = L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
        {
          attribution:
            '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          minZoom: 4,
          maxZoom: 20,
        }
      );

      if (this.mapViewOption === "openstreetmap") {
        osmLayer = L.tileLayer(
          `https://tile.openstreetmap.org/{z}/{x}/{y}.png`,
          {
            attribution:
              '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            minZoom: 4,
            maxZoom: 20,
            className: "map-tiles",
          }
        );
      }

      return osmLayer;
    },

    updateMap() {
      let osmLayer = this.getOsmLayer();
      if (this.map) {
        this.map.eachLayer((layer) => {
          if (layer.myTag) {
            if (layer.myTag == "boundsGeoJSON") {
              layer.setStyle({
                fill: this.fillFunction(layer.feature),
              });
            }
          }
        });
      } else {
        this.map = L.map(document.querySelector("#mapContainer"));
        osmLayer.addTo(this.map);

        // If no fields, set map to center of US
        if (this.bounds.coordinates.length === 0) {
          this.map.setView([39.8283, -98.5795], 5);
        }

        this.map.on("mousemove", this.handleMapHover);
        this.map.on("click", this.handleMapClick);
        this.map.on("dblclick", this.handleMapDoubleClick);
        this.map.on("contextmenu", this.handleMapRightClick);

        this.map.features = {};

        // FeatureGroup is to store editable layers
        this.drawnFeatures = new L.FeatureGroup().addTo(this.map);
        this.getBoundLayers();

        var drawControl = new L.Control.Draw({
          draw: {
            polyline: true, // Enable line drawing
            polygon: true, // Disable other shapes
            rectangle: false,
            circle: true,
            marker: false,
            line: true,
          },
        });
        // var drawControl = new L.Control.Draw({
        //   draw: {
        //     marker: false,
        //     line: false,
        //     rectangle: false,
        //     circle: true,
        //     circlemarker: false,
        //     polyline: false,
        //   },
        // })
        this.map.addControl(drawControl);

        this.map.addControl(L.Control.geocoder());

        // Switch between satellite and street view
        this.viewBtn = L.easyButton("fa-globe", this.switchView, "Switch View");
        this.viewBtn.addTo(this.map);
        this.viewBtn.setPosition("topright");

        // Reset Zoom View
        this.resetZoomBtn = L.easyButton(
          "fa-crosshairs",
          this.resetZoom,
          "Reset zoom"
        );
        // Clear Changes icon
        this.clearChangesBtn = L.easyButton(
          "fa-trash",
          this.confirmClearAll,
          "Clear all un-saved changes"
        );
        this.resetZoomBtn.addTo(this.map);
        this.clearChangesBtn.addTo(this.map);
      }

      // Draw Created Event Handler
      this.map.on("draw:created", function (e) {
        var layer = e.layer;
        this.recentlyCreatedLayer = layer;
        this.recentlyCreatedLayer.id = L.stamp(layer);
        this.recentlyCreatedLayer.isNewBoundary = true;
        this.recentlyCreatedLayer.feature = {
          type: "Feature",
          properties: {
            field: {
              acreage: null,
              id: null,
              farm: {
                client_id: null,
                client_name: null,
                id: null,
                name: null,
                org_node_id: null,
              },
              name: null,
            },
            paddock: {
              name: null,
              fieldId: null,
            },
          },
        };
      });

      this.map.on("draw:created", this.drawCreated);
      this.map.on("draw:editvertex", this.saveChanges);

      this.showSnackBar(
        true,
        "blue",
        5000,
        "Select an existing field or click the polygon icon on the top left to create a new boundary."
      );
      osmLayer = null;
    },

    zoomToBounds() {
      if (this.bounds.coordinates.length === 0 || !this.map) return;
      const geoJSON = L.geoJson(this.bounds);
      this.map.fitBounds(geoJSON.getBounds());
    },

    zoomToSelectedField(selectedField) {
      if (selectedField.geometry.coordinates.length === 0 || !this.map) return;
      const geoJSON = L.geoJson(selectedField);
      this.map.fitBounds(geoJSON.getBounds());
    },

    findFieldFromLatLng(latlng) {
      const { lat, lng } = latlng;
      const pt = point([lng, lat]);

      const drawnLayers = this.drawnFeatures.getLayers();
      const filtered = drawnLayers.filter((layer) => {
        const layerGeoJson = layer.toGeoJSON();
        const fieldMPoly = polygon(layerGeoJson.geometry.coordinates);
        return booleanPointInPolygon(pt, fieldMPoly);
      });
      if (filtered.length === 1) {
        return filtered[0];
      } else if (filtered.length > 1) {
        const type = this.tab == 2 ? "paddock" : "field"
        return filtered.find((layer) => layer.type === type);
      }
      // return drawnLayers.find(layer => {
      //   const layerGeoJson = layer.toGeoJSON()
      //   const fieldMPoly = polygon(layerGeoJson.geometry.coordinates)
      //   return booleanPointInPolygon(pt, fieldMPoly)
      // })
    },

    findSelectedFieldFromLatLng(latlng) {
      const { lat, lng } = latlng;
      const pt = point([lng, lat]);
      return this.fields.find((field) => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates);
        return booleanPointInPolygon(pt, fieldMPoly);
      });
    },

    multiPolygonToPolygon(coordinates, centroid, properties) {
      return {
        centroid: centroid,
        geometry: {
          type: "Polygon",
          coordinates: coordinates,
        },
        properties: properties,
        type: "Feature",
      };
    },

    handleMapDoubleClick(e) {
      const { latlng } = e;
      this.setRightClickData();
      this.clickedLayer = this.findFieldFromLatLng(latlng);
      if (!this.clickedLayer) {
        this.resetZoom();
        this.resetPastureInfo();
      }
    },

    handleMapClick(e) {
      const { latlng } = e;

      if (this.rightClickData) {
        this.setRightClickData();
        return;
      }

      this.resetLeftPanel();
      if (this.isCreatingSubDivide) {
        this.drawnFeatures.eachLayer((layer) => {
          layer.editing.disable();
        });
        this.resetPastureInfo();
        this.showSnackBar(false, "blue", -1, null);
      } else {
        this.clickedLayer = this.findFieldFromLatLng(latlng);
        if (this.clickedLayer) {
          this.drawnFeatures.eachLayer((layer) => {
            if (layer.id !== this.clickedLayer.id) layer.editing.disable();
          });
          const clickedField = this.clickedLayer.toGeoJSON();
          if (this.clickedLayer.type === "field") {
            if (clickedField.properties.field) {
              const field = clickedField.properties.field;

              if (!this.uneditableFields[field.id]) {
                this.clickedLayer.editing.enable();
                this.clickedUneditableField = false;
                this.uneditableFieldMessage = "";
                this.selectedUneditableFieldName = "";
                this.parentPasture = field.id;
                this.parentOrgNodeId = field.farm.org_node_id;
              } else {
                this.clickedUneditableField = true;
                this.selectedUneditableFieldName = field.name;
                this.uneditableFieldMessage = this.uneditableFields[field.id];
                this.parentPasture = null;
                this.parentOrgNodeId = null;
                return;
              }

              this.selectedOrg = field.farm.org_node_id
                ? this.organizationOptions.find(
                  (org) => org.id === field.farm.org_node_id
                ).id
                : null;
              this.selectedClient = field.farm.client_id
                ? this.clientOptions.find(
                  (client) => client.id === field.farm.client_id
                )
                : field.farm.client_name;
              this.selectedFarm = field.farm.id
                ? this.farmOptions.find((farm) => farm.id === field.farm.id)
                : field.farm.name;
              this.fieldName = field.name;

              if (field.acreage)
                this.totalAreaBySelectedUnit =
                  this.selectedUnits === "acres"
                    ? field.acreage
                    : this.convertAreaToSelectedUnit(field.acreage);
              else this.calculateTotalArea(clickedField);

              let showText =
                "Drag points to adjust existing boundary. Tap between points to add a point.";
              if (this.clickedLayer.isNewBoundary) {
                showText =
                  "Drag points to adjust new boundary. Tap between points to add a point.";
              }
              this.showSnackBar(true, "blue", -1, showText);
            }
          } else if (this.clickedLayer.type === "paddock") {
            const paddockId = clickedField.properties.paddock.id;
            if (!this.uneditableFields[paddockId]) {
              this.clickedLayer.editing.enable();
              this.clickedUneditableField = false;
              this.uneditableFieldMessage = "";
              this.selectedUneditableFieldName = "";
              this.parentPasture = clickedField.properties.paddock.fieldId;
            } else {
              this.clickedUneditableField = true;
              this.selectedUneditableFieldName = field.name;
              this.uneditableFieldMessage = this.uneditableFields[field.id];
              this.parentPasture = null;
              return;
            }

            this.paddockName = clickedField.properties.paddock.name;
          }

          // if (this.clickedLayer.type === 'paddock') {
          //   this.paddockName =
          // }

          this.zoomToSelectedField(clickedField);
          this.isClickedLayerEqualOrgPolygon = this.isEqualPolygon(
            this.clickedLayer
          );
        } else {
          this.drawnFeatures.eachLayer((layer) => {
            layer.editing.disable();
          });
          this.resetPastureInfo();
          this.showSnackBar(false, "blue", -1, null);
        }
      }
      this.numPolygons = Object.keys(this.map.features).length;
    },

    handleMapRightClick(e) {
      const { latlng } = e;
      const rightClickedLayer = this.findFieldFromLatLng(latlng);

      const { x, y } = e.containerPoint;
      if (this.clickedLayer === rightClickedLayer) {
        if (this.clickedLayer.isNewBoundary) {
          this.setRightClickData({
            x,
            y,
            options: [{ text: "Delete Boundary", value: "delete-boundary" }],
          });
        } else {
          this.setRightClickData({
            x,
            y,
            options: [
              { text: this.editFieldLabel, value: "edit-field-name" },
              { text: "Delete Boundary", value: "delete-boundary" },
            ],
          });
        }
      } else {
        this.setRightClickData();
      }
    },

    handleMenuOptionClicked(option) {
      if (option === "delete-boundary")
        this.confirmBeforeDeletingBoundary = true;
      else if (option === "edit-field-name") {
        this.clickedEditFieldNameOption = true;
        this.showEditSection = true;
      }
    },

    deleteUnsavedBoundary() {
      const fieldName = this.clickedLayer.feature.properties.field.name;
      delete this.map.features[this.clickedLayer.id];
      this.drawnFeatures.removeLayer(this.clickedLayer);
      this.clickedLayer = null;
      this.confirmBeforeDeletingBoundary = false;
      this.showSnackBar(
        true,
        "green",
        2500,
        `Successfully deleted boundary [${fieldName}]`
      );
    },

    async deleteBoundary() {
      if (this.clickedLayer.type === "paddock") {
        const paddockId = this.clickedLayer.feature.properties.paddock.id;
        await PaddocksAPI.pastureDelete(paddockId)
          .then(() => {
            delete this.map.features[this.clickedLayer.id];
            this.drawnFeatures.removeLayer(this.clickedLayer);
            this.clickedLayer = null;
            this.confirmBeforeDeletingBoundary = false;
            this.showSnackBar(
              true,
              "green",
              3500,
              `Paddock Deletion Request Received. Please Refresh The Application In A Few Minutes.`
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const fieldId = this.clickedLayer.feature.properties.field.id;
        await PasturesAPI.fieldDelete(fieldId)
          .then(() => {
            delete this.map.features[this.clickedLayer.id];
            this.drawnFeatures.removeLayer(this.clickedLayer);
            this.clickedLayer = null;
            this.confirmBeforeDeletingBoundary = false;
            this.showSnackBar(
              true,
              "green",
              3500,
              `Field Deletion Request Received. Please Refresh The Application In A Few Minutes.`
            );
          })
          .catch((error) => {
            this.errorMessage = "Unable to delete field:";
            this.showSnackBar(true, "red", 5000, error.response.data.message);
            this.confirmBeforeDeletingBoundary = false;
          });
      }
    },

    async deletePaddock() { },

    clearUnsavedBoundary() {
      if (this.clickedLayer.feature.properties.field.id) {
        const fieldId = this.clickedLayer.feature.properties.field.id;
        const fieldName = this.clickedLayer.feature.properties.field.name;
        delete this.map.features[this.clickedLayer.id];
        this.drawnFeatures.removeLayer(this.clickedLayer);
        this.clickedLayer = null;
        this.getBoundLayerByFieldId(fieldId);
        this.confirmBeforeClearingChanges = false;
        this.showSnackBar(
          true,
          "green",
          2500,
          `Successfully cleared unsaved changes for Field [${fieldName}]`
        );
      }
    },

    handleMapHover(e) {
      const { latlng } = e;
      const hoveredField = this.findSelectedFieldFromLatLng(latlng);
      if (hoveredField) {
        const { field } = hoveredField.properties;
        const { x, y } = e.containerPoint;
        const totalArea =
          this.selectedUnits === "acres"
            ? field.acreage
            : this.convertAreaToSelectedUnit(field.acreage);
        this.setHoverData({
          x,
          y,
          fieldName: field.name,
          farmName: field.farm.name,
          acreage: totalArea,
        });
      } else {
        this.setHoverData();
      }
    },

    confirmClearAll() {
      if (this.numPolygons === 0) {
        this.showSnackBar(true, "red", 5000, "No new boundaries to clear.");
      } else {
        this.confirmClearAllChanges = true;
      }
    },

    clearAllChanges() {
      this.confirmClearAllChanges = false;

      for (let featureKey in this.map.features) {
        delete this.map.features[featureKey];
      }
      //delete this.map.features[this.clickedLayer.id]
      this.map.features = {};
      this.numPolygons = 0;

      const drawnLayers = this.drawnFeatures.getLayers();
      drawnLayers.forEach((layer) => {
        //var fieldId = layer.feature.properties.field.id
        this.drawnFeatures.removeLayer(layer);
        //this.getBoundLayerByFieldId(fieldId)
      });
      this.getBoundLayers();

      //this.resetZoom()
      this.resetPastureInfo();
      this.showSnackBar(false, "blue", -1, null);
      this.closeLeftPanel();
    },

    resetLeftPanel() {
      this.clickedEditFieldNameOption = false;
      this.clickedEditPaddockNameOption = false;
      this.showEditSection = false;
      this.showInfoSection = false;
      this.numPolygons = Object.keys(this.map.features).length;
    },

    closeLeftPanel() {
      this.handleMapClick({ latlng: { lat: 0, lng: 0 } });
    },

    async editFieldName() {
      const payload = {
        fieldId: this.clickedLayer.feature.properties.field.id,
        farmId: this.clickedLayer.feature.properties.field.farm.id,
        fieldName: this.newFieldName,
      };

      await PasturesAPI.updateFieldName(payload)
        .then(() => {
          this.clickedLayer.feature.properties.field.name = this.newFieldName;
          this.fieldName = this.newFieldName;
          this.showSnackBar(
            true,
            "blue",
            5000,
            "Field name updated successfully."
          );
          this.resetLeftPanel();
        })
        .catch((error) => {
          this.showSnackBar(true, "red", 5000, error.response.data.message);
        });
    },

    async editPaddockName() {
      const payload = {
        fieldId: this.clickedLayer.feature.properties.paddock.fieldId,
        paddockId: this.clickedLayer.feature.properties.paddock.id,
        paddockName: this.newPaddockName,
      };

      await PaddocksAPI.updatePaddockName(payload)
        .then(() => {
          this.clickedLayer.feature.properties.paddock.name =
            this.newPaddockName;
          this.paddockName = this.newPaddockName;
          this.showSnackBar(
            true,
            "blue",
            5000,
            "Paddock name updated successfully."
          );
          this.resetLeftPanel();
        })
        .catch((error) => {
          this.showSnackBar(true, "red", 5000, error.response.data.message);
        });
    },

    confirmChanges() {
      // this.map.features[this.clickedLayer.id] = this.clickedLayer.toGeoJSON();
      this.numPolygons = Object.keys(this.map.features).length;

      if (this.isCreatingSubDivide || this.clickedLayer.type == 'paddock') {
        this.uploadPaddockChanges();
      } else {
        this.uploadChanges();
      }
    },

    saveChanges() {
      var editedPolygon = this.clickedLayer.toGeoJSON();

      if (this.clickedLayer.feature.properties.hasOwnProperty("field")) {
        //reset any drawn fields if user clicks to edit an existing field
        if (this.clickedLayer.feature.properties.field.id != null) {
          //if has field.id this means it is a field that is already in db
          for (let featureKey in this.mapLayerTracker) {
            this.map.removeLayer(this.mapLayerTracker[featureKey]);

            //delete this.map.features[featureKey]
          }
          for (let featureKey in this.mapLayerTracker) {
            delete this.map.features[featureKey];
          }
          this.map.features = {};
        }
      }

      const drawnLayers = this.drawnFeatures.getLayers();
      let mapFeatureIter = 0;
      drawnLayers.forEach((layer) => {
        if (
          (layer.feature.type === "field" &&
            layer.feature.properties.field.id ===
            this.clickedLayer.feature.properties.field.id) ||
          (layer.feature.type === "paddock" &&
            layer.feature.properties.paddock.id ===
            this.clickedLayer.feature.properties.paddock.id)
        ) {
          mapFeatureIter = mapFeatureIter + 1;
          //var existingPolygon = polygon(layer.feature.geometry.coordinates[0])

          var isIntersecting = booleanIntersects(
            editedPolygon,
            layer.toGeoJSON()
          );
          var isOverlapping = booleanOverlap(editedPolygon, layer.toGeoJSON());

          if (!isIntersecting && !isOverlapping) {
            this.map.features[layer.id] = layer.toGeoJSON();
          }
        }
      });

      this.map.features[this.clickedLayer.id] = editedPolygon;
      this.map.features[this.clickedLayer.id].isEditedBoundary = true;
      this.numPolygons = Object.keys(this.map.features).length;

      if (mapFeatureIter > this.numPolygons) {
        this.showSnackBar(
          true,
          " red",
          7500,
          "You have likely created a new intersection on an existing multipolygon field boundary. If these changes are saved, the existing boundary polygon that was intersected will be removed."
        );
      }

      //this.numPolygons = Object.keys(this.map.features).length
      if (this.clickedLayer.type === "field") {
        this.map.features[this.clickedLayer.id].properties.field.acreage =
          this.calculateTotalArea(this.map.features[this.clickedLayer.id]);
      } else {
        // this.map.features[this.clickedLayer.id].properties.field.acreage =
        // this.calculateTotalArea(this.map.features[this.clickedLayer.id])
      }

      this.isClickedLayerEqualOrgPolygon = this.isEqualPolygon(
        this.clickedLayer
      );
    },

    resetZoom() {
      this.zoomToBounds();
    },

    switchView() {
      // Remove the current tile layer from the map
      let currentOsmLayer = this.getOsmLayer();
      this.map.removeLayer(currentOsmLayer);

      // Update the tile layer based on the map view option
      if (this.mapViewOption === "openstreetmap")
        this.mapViewOption = "satellite";
      else this.mapViewOption = "openstreetmap";
      let osmLayer = this.getOsmLayer();
      osmLayer.addTo(this.map);
    },

    clearChanges() {
      if (this.clickedLayer) {
        // existing field

        if (this.clickedLayer.type === "field") {
          if (this.clickedLayer.feature.properties.field.id) {
            const fieldId = this.clickedLayer.feature.properties.field.id;
            delete this.map.features[this.clickedLayer.id];
            this.clickedLayer.setStyle({
              color: "red",
              fillColor: "red",
              fill: true,
            });
            // this.drawnFeatures.removeLayer(this.clickedLayer)
            this.clickedLayer = null;
            // this.getBoundLayerByFieldId(fieldId)
          }
        } else if (this.clickedLayer.type === "paddock") {
          if (this.clickedLayer.feature.properties.paddock.id) {
            const paddockId = this.clickedLayer.feature.properties.paddock.id;
            delete this.map.features[this.clickedLayer.id];
            this.clickedLayer.setStyle({
              color: "red",
              fillColor: "red",
              fill: true,
            });
            // this.drawnFeatures.removeLayer(this.clickedLayer)
            this.clickedLayer = null;
            // this.getBoundLayerByFieldId(fieldId)
          }
        }

        // newly created field but not yet saved in the system
        else {
          delete this.map.features[this.clickedLayer.id];
          // this.drawnFeatures.removeLayer(this.clickedLayer)
          this.clickedLayer = null;
        }
      }
      // for (let featureKey in this.mapLayerTracker) {
      //   this.map.removeLayer(this.mapLayerTracker[featureKey])
      // }
    },

    async uploadChanges() {
      var featureGroup = [];
      if (Object.keys(this.map.features)) {
        featureGroup.push();
      }

      // Assign the same field properties to all drawn polygons
      // Note: If multiple polygons have different field properties,
      // create multipolygons using the field properties of the currently selected layer.
      let properties = {
        organization_id:
          this.clickedLayer.feature.properties.field.farm.org_node_id,
        client: this.clickedLayer.feature.properties.field.farm.client_name
          ? this.clickedLayer.feature.properties.field.farm.client_name
          : this.clientOptions.find(
            (client) =>
              client.id ===
              this.clickedLayer.feature.properties.field.farm.client_id
          ).name,
        farm: this.clickedLayer.feature.properties.field.farm.name,
        field: this.clickedLayer.feature.properties.field.name,
        fieldId: this.clickedLayer.feature.properties.field.id,
        fieldSave: this.clickedLayer.feature.properties.field,
      };

      //move this from previous function  so that we canreset value of field property at the end
      Object.keys(this.map.features).forEach((key) => {
        let feature = this.map.features[key];
        feature.properties = properties;
        featureGroup.push(feature);
      });

      //find unique fieldid, if only 1, allow filter

      var fieldIDArray = [];
      featureGroup.forEach((feature) => {
        if (feature.properties.fieldId) {
          fieldIDArray.push(feature.properties.fieldId);
        }
      });
      fieldIDArray = [...new Set(fieldIDArray)];
      //get unique fieldID list so we can match the field with non edited parts of the multipolygon for shapefile uplaod

      if (fieldIDArray.length > 1) {
        this.showSnackBar(
          true,
          " red",
          2500,
          "Can only edit one field at a time. Please refresh or click Cancel."
        );
        return;
      }

      this.drawnFeatures.eachLayer(function (layer) {
        if (layer.isNewBoundary) {
          layer.setStyle({ color: "red", fillColor: "red" });
        }
      });

      // Extract GeoJson from featureGroup
      // Iterate through each and generate the shapefile for each field
      //  and run it through our upload process pipeline
      if (featureGroup.length > 0) {
        for (let i = 0; i < featureGroup.length; i++) {
          var editedPolygon = featureGroup[i];
          //only check for overlap on save/upload
          var isOverlapping = this.isEditedPolygonOverlapping(editedPolygon);
          if (isOverlapping) {
            Object.keys(this.map.features).forEach((key) => {
              let feature = this.map.features[key];
              feature.properties.field = feature.properties.fieldSave;
              //reset
            });

            this.showSnackBar(
              true,
              " red",
              2500,
              "The new boundary is overlapping with other field(s). Please redraw the boundary."
            );
            return;
          }
        }

        //featureGroup.forEach(async feature => {
        const properties = featureGroup[0].properties;
        //we would need to not loop here if we do the multi part creation
        const todayDate = moment().format("YYYY-MM-DD_h:mm:ss");
        const folderName = `Field_${properties.field}_CreatedBy_BoundaryEditor_${todayDate}`;
        const options = {
          folder: folderName,
          filename: folderName,
          outputType: "blob",
          compression: "DEFLATE",
          types: {
            point: "mypoints",
            polygon: "mypolygons",
            polyline: "mylines",
          },
        };
        const content = await shpWrite.zip(
          {
            type: "FeatureCollection",
            features: featureGroup,
          },
          options
        );

        // Create a zip file object from the zip file blob
        var zipFileObject = new File([content], folderName + ".zip", {
          type: "application/zip",
        });

        this.updateRanchBoundary({
          orgId: properties.organization_id,
          datasetType: "boundary-ranch",
          file: zipFileObject,
          fieldName: properties.field,
          fieldId: properties.fieldId,
        });

        Object.keys(this.map.features).forEach((key) => {
          let feature = this.map.features[key];
          feature.properties.field = feature.properties.fieldSave;
          //reset
        });

        if (this.clickedLayer.isNewBoundary) {
          let message =
            "Field boundary has been saved. However, the newly created Field will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          if (this.isRanchForce) {
            message =
              "Pasture boundary has been saved. However, the newly created Pasture will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          }

          this.showSnackBar(true, "green", 5000, message);
        } else {
          let message =
            "Field boundary has been updated. However, the newly updated Field will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          if (this.isRanchForce) {
            message =
              "Pasture boundary has been updated. However, the newly updated Pasture will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          }

          this.showSnackBar(true, "green", 5000, message);
        }
        //})
      }

      for (let featureKey in this.map.features) {
        delete this.map.features[featureKey];
      }

      // this.map.removeLayer(this.map.recentlyCreatedLayer)
      this.map.features = {};
      this.isClickedLayerEqualOrgPolygon = true;
      this.clearChanges();
    },

    async uploadPaddockChanges() {
      var featureGroup = [];

      Object.keys(this.map.features).forEach((key) => {
        let feature = this.map.features[key];
        let properties = {}

        if (feature.isNewBoundary) {
          properties = {
            organization_id: this.parentOrgNodeId,
            fieldId: this.parentPasture,
            paddock: this.recentlyCreatedPaddocks[key],
            paddockSave: feature.properties.paddock,
          }
        } else if (feature.isEditedBoundary) {
          properties = {
            organization_id: this.parentOrgNodeId,
            fieldId: this.parentPasture,
            paddock: this.clickedLayer.feature.properties.paddock.name,
            paddockSave: feature.properties.paddock,
          }
        }

        feature.properties = properties;
        featureGroup.push(feature);
      });

      this.drawnFeatures.eachLayer(function (layer) {
        if (layer.isNewBoundary) {
          layer.setStyle({ color: "red", fillColor: "red" });
        }
      });

      // var paddockIdArray = [];
      // featureGroup.forEach((feature) => {
      //   if (feature.properties.paddockId) {
      //     paddockIdArray.push(feature.properties.paddockId);
      //   }
      // });
      // paddockIdArray = [...new Set(paddockIdArray)];

      // if (paddockIdArray.length > 1) {
      //   this.showSnackBar(
      //     true,
      //     " red",
      //     2500,
      //     "Can only edit one paddock at a time. Please refresh or click Cancel."
      //   );
      //   return;
      // }

      if (featureGroup.length > 0) {
        for (const feature of featureGroup) {

          const properties = feature.properties;
          const todayDate = moment().format("YYYY-MM-DD_h:mm:ss");
          const folderName = `Field_${properties.fieldId}_CreatedBy_BoundaryEditor_${todayDate}`;
          const options = {
            folder: folderName,
            filename: folderName,
            outputType: "blob",
            compression: "DEFLATE",
            types: {
              point: "mypoints",
              polygon: "mypolygons",
              polyline: "mylines",
            },
          };
          const content = await shpWrite.zip(
            {
              type: "FeatureCollection",
              features: [feature],
            },
            options
          );

          var zipFileObject = new File([content], folderName + ".zip", {
            type: "application/zip",
          });

          await this.updateRanchPaddocks({
            orgId: properties.organization_id,
            fieldId: properties.fieldId,
            datasetType: "paddocks-ranch",
            file: zipFileObject,
            paddockName: properties.paddock,
          });

          // Object.keys(this.map.features).forEach((key) => {
          //   let feature = this.map.features[key];
          //   feature.properties.paddock = feature.properties.paddockSave;
          //   //reset
          // });

          // if (this.clickedLayer.isNewBoundary) {
          //   let message =
          //     "Paddock boundary has been saved. However, the newly created Paddock will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          //   if (this.isRanchForce) {
          //     message =
          //       "Paddock boundary has been saved. However, the newly created Paddock will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          //   }

          //   this.showSnackBar(true, "green", 5000, message);
          // } else {
          //   let message =
          //     "Paddock boundary has been updated. However, the newly updated Paddock will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          //   if (this.isRanchForce) {
          //     message =
          //       "Paddock boundary has been updated. However, the newly updated Paddock will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          //   }

          //   this.showSnackBar(true, "green", 5000, message);
          // }
        }

        // const properties = featureGroup[0].properties;
        // const todayDate = moment().format("YYYY-MM-DD_h:mm:ss");
        // const folderName = `Field_${properties.fieldId}_CreatedBy_BoundaryEditor_${todayDate}`;
        // const options = {
        //   folder: folderName,
        //   filename: folderName,
        //   outputType: "blob",
        //   compression: "DEFLATE",
        //   types: {
        //     point: "mypoints",
        //     polygon: "mypolygons",
        //     polyline: "mylines",
        //   },
        // };
        // const content = await shpWrite.zip(
        //   {
        //     type: "FeatureCollection",
        //     features: featureGroup,
        //   },
        //   options
        // );

        // var zipFileObject = new File([content], folderName + ".zip", {
        //   type: "application/zip",
        // });

        // this.updateRanchPaddocks({
        //   orgId: properties.organization_id,
        //   fieldId: properties.fieldId,
        //   datasetType: "paddocks-ranch",
        //   file: zipFileObject,
        //   paddockName: properties.paddock,
        // });

        // Object.keys(this.map.features).forEach((key) => {
        //   let feature = this.map.features[key];
        //   feature.properties.paddock = feature.properties.paddockSave;
        //   //reset
        // });

        if (this.clickedLayer.isNewBoundary) {
          let message =
            "Paddock boundary has been saved. However, the newly created Paddock will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          if (this.isRanchForce) {
            message =
              "Paddock boundary has been saved. However, the newly created Paddock will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          }

          this.showSnackBar(true, "green", 5000, message);
        } else {
          let message =
            "Paddock boundary has been updated. However, the newly updated Paddock will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          if (this.isRanchForce) {
            message =
              "Paddock boundary has been updated. However, the newly updated Paddock will not appear in the application until after the data aggregation and AI processing pipelines have completed.";
          }

          this.showSnackBar(true, "green", 5000, message);
        }

        this.isCreatingSubDivide = false
      }

      for (let featureKey in this.map.features) {
        delete this.map.features[featureKey];
      }

      for (let key in this.recentlyCreatedPaddocks) {
        delete this.recentlyCreatedPaddocks[key];
      }

      this.isCreatingSubDivide = false;

      // this.map.removeLayer(this.map.recentlyCreatedLayer)
      this.map.features = {};
      this.isClickedLayerEqualOrgPolygon = true;
      this.clearChanges();
    },

    drawCreated(e) {
      const layer = e.layer;

      //let layer = this.map.recentlyCreatedLayer
      if (this.map.recentlyCreatedLayer.hasOwnProperty("_mRadius")) {
        //let feature = this.map.recentlyCreatedLayer.feature
        var createdPolygon = buffer(
          this.map.recentlyCreatedLayer.toGeoJSON(),
          this.map.recentlyCreatedLayer._mRadius,
          {
            units: "meters",
          }
        );
        let layerID = this.map.recentlyCreatedLayer.id;
        this.map.recentlyCreatedLayer = new L.GeoJSON(createdPolygon);
        this.map.recentlyCreatedLayer =
          this.map.recentlyCreatedLayer._layers[
          Object.keys(this.map.recentlyCreatedLayer._layers)[0]
          ]; //not pretty, I need help lol
        this.map.recentlyCreatedLayer.isNewBoundary = true;
        this.map.recentlyCreatedLayer.id = layerID;
      }

      if (this.isCreatingSubDivide) {
        if (layer instanceof L.Polygon) {
          this.map.recentlyCreatedLayer.type = "paddock";
          this.map.recentlyCreatedLayer.feature.properties.paddock.acreage =
            this.totalAreaBySelectedUnit;
          this.clickedLayer = this.map.recentlyCreatedLayer;
          this.clickedLayer.feature.properties.paddock.fieldId =
            this.parentPasture;

          //if use has edited an existing field from db, but not saved, and then started
          //drawing a new field, we don't want to accidently add those fields together in the upload
          //so we remove the previous field from this.map.features
          if (Object.keys(this.map.features).length > 0) {
            for (const [key, obj] of Object.entries(this.map.features)) {
              if (Object.keys(obj.properties).includes("field")) {
                if (
                  Object.keys(obj.properties.field).includes("voxel_area_ac")
                ) {
                  delete this.map.features[key];
                }
              }
            }
          }
          this.map.features[this.map.recentlyCreatedLayer.id] = this.map.recentlyCreatedLayer.toGeoJSON();
          this.recentlyCreatedPaddocks[this.map.recentlyCreatedLayer.id] = null;
          this.map.features[this.map.recentlyCreatedLayer.id].isNewBoundary = true;
          this.mapLayerTracker[this.map.recentlyCreatedLayer.id] = this.map.recentlyCreatedLayer;
          this.numPolygons = Object.keys(this.map.features).length;
          this.drawnFeatures.addLayer(this.map.recentlyCreatedLayer);
          this.calculateTotalArea();

          this.isClickedLayerEqualOrgPolygon = false;
        } else if (layer instanceof L.Polyline) {
          const lineCoords = layer.getLatLngs();
          const lineGeoJSON = L.polyline(lineCoords).toGeoJSON();
          const drawnLayers = this.drawnFeatures.getLayers();

          drawnLayers.forEach((layer) => {
            const layerGeoJson = layer.toGeoJSON();

            // Convert the polygon and line to Turf.js GeoJSON features
            const fieldPolygon = turf.polygon(
              layerGeoJson.geometry.coordinates
            );
            const lineString = turf.lineString(
              lineGeoJSON.geometry.coordinates
            );

            // Step 1: Get intersection points
            const intersectPoints = turf.lineIntersect(
              fieldPolygon,
              lineString
            );

            if (intersectPoints.features.length > 1) {
              const splitResult = polygonSplitter(fieldPolygon, lineString);

              // this.clickedLayer = this.map.recentlyCreatedLayer
              // this.clickedLayer.feature.properties.paddock.fieldId = this.parentPasture

              splitResult.geometry.coordinates.forEach((pCoords) => {
                const newPolygon = turf.polygon([pCoords[0]]);
                // L.geoJSON(newPolygon).addTo(this.map);

                const newLayer = L.geoJSON(newPolygon).getLayers()[0];

                this.map.recentlyCreatedLayer = newLayer;
                this.map.recentlyCreatedLayer.id = L.stamp(newLayer);
                this.map.recentlyCreatedLayer.isNewBoundary = true;
                this.map.recentlyCreatedLayer.type = "paddock";
                this.map.recentlyCreatedLayer.feature = {
                  type: "Feature",
                  properties: {
                    field: {
                      acreage: null,
                      id: null,
                      farm: {
                        client_id: null,
                        client_name: null,
                        id: null,
                        name: null,
                        org_node_id: null,
                      },
                      name: null,
                    },
                    paddock: {
                      name: null,
                      fieldId: null,
                    },
                  },
                };

                this.map.recentlyCreatedLayer.feature.properties.paddock.fieldId =
                this.parentPasture;
                this.drawnFeatures.addLayer(this.map.recentlyCreatedLayer);

                const labelText = `Paddock: ${this.map.recentlyCreatedLayer.id}`;
                this.map.recentlyCreatedLayer.bindTooltip(labelText, { permanent: true, direction: "center" });

                //if use has edited an existing field from db, but not saved, and then started
                //drawing a new field, we don't want to accidently add those fields together in the upload
                //so we remove the previous field from this.map.features
                if (Object.keys(this.map.features).length > 0) {
                  for (const [key, obj] of Object.entries(this.map.features)) {
                    if (Object.keys(obj.properties).includes("field")) {
                      if (
                        Object.keys(obj.properties.field).includes(
                          "voxel_area_ac"
                        )
                      ) {
                        delete this.map.features[key];
                      }
                    }
                  }
                }


                this.map.features[this.map.recentlyCreatedLayer.id] = this.map.recentlyCreatedLayer.toGeoJSON();
                this.map.features[this.map.recentlyCreatedLayer.id].isNewBoundary = true;
                this.recentlyCreatedPaddocks[this.map.recentlyCreatedLayer.id] = null;
                // this.mapLayerTracker[this.map.recentlyCreatedLayer.id] = this.map.recentlyCreatedLayer;
                this.numPolygons = Object.keys(this.map.features).length;
                this.calculateTotalArea();

                this.isClickedLayerEqualOrgPolygon = false;
              });

              // const intersectCoords = intersectPoints.features.map(point => point.geometry.coordinates);

              // // Step 3: Add one of the original polygon's vertices (e.g., the first vertex)
              // const originalPolygonCoords = layerGeoJson.geometry.coordinates[0]; // Assuming it's a single ring
              // const firstPolygonVertex = originalPolygonCoords[0];

              // // Step 4: Combine points to form a new polygon
              // const newPolygonCoords = [...intersectCoords, firstPolygonVertex, intersectCoords[0]]; // Close the loop

              // // Step 5: Create the new polygon
              // const newPolygon = turf.polygon([newPolygonCoords]);

              // const newLayer = L.geoJSON(newPolygon).getLayers()[0];

              // this.map.recentlyCreatedLayer = newLayer
              // this.map.recentlyCreatedLayer.id = L.stamp(newLayer)
              // this.map.recentlyCreatedLayer.isNewBoundary = true
              // this.map.recentlyCreatedLayer.type = 'paddock'
              // this.map.recentlyCreatedLayer.feature = {
              //   type: "Feature",
              //   properties: {
              //     field: {
              //       acreage: null,
              //       id: null,
              //       farm: {
              //         client_id: null,
              //         client_name: null,
              //         id: null,
              //         name: null,
              //         org_node_id: null,
              //       },
              //       name: null,
              //     },
              //     paddock: {
              //       name: null,
              //       fieldId: null,
              //     }
              //   },
              // }

              // this.clickedLayer = this.map.recentlyCreatedLayer
              // this.clickedLayer.feature.properties.paddock.fieldId = this.parentPasture

              // L.geoJSON(newPolygon).addTo(this.map);
            } else {
              console.log("No valid division of the polygon was possible.");
            }
          });
        }
      } else {
        var createdPolygon = this.map.recentlyCreatedLayer.toGeoJSON();
        var isOverlapping = this.isCreatedPolygonOverlapping(createdPolygon);

        if (isOverlapping) {
          this.map.removeLayer(this.map.recentlyCreatedLayer);
          delete this.map.features[this.map.recentlyCreatedLayer.id];
          delete this.map.recentlyCreatedLayer;
        } else {
          //this.calculateTotalArea(this.featureGroup)
          this.map.recentlyCreatedLayer.type = "field";
          this.map.recentlyCreatedLayer.feature.properties.field.acreage =
            this.totalAreaBySelectedUnit;
          this.clickedLayer = this.map.recentlyCreatedLayer;

          //if use has edited an existing field from db, but not saved, and then started
          //drawing a new field, we don't want to accidently add those fields together in the upload
          //so we remove the previous field from this.map.features
          if (Object.keys(this.map.features).length > 0) {
            for (const [key, obj] of Object.entries(this.map.features)) {
              if (Object.keys(obj.properties).includes("field")) {
                if (
                  Object.keys(obj.properties.field).includes("voxel_area_ac")
                ) {
                  delete this.map.features[key];
                }
              }
            }
          }
          this.map.features[this.clickedLayer.id] =
            this.clickedLayer.toGeoJSON();
          this.mapLayerTracker[this.clickedLayer.id] =
            this.map.recentlyCreatedLayer;
          this.numPolygons = Object.keys(this.map.features).length;
          this.drawnFeatures.addLayer(this.map.recentlyCreatedLayer);
          this.calculateTotalArea();

          this.isClickedLayerEqualOrgPolygon = false;
          this.zoomToSelectedField(this.clickedLayer.toGeoJSON());

          if (!this.alertShown) {
            this.showSnackBar(
              true,
              "green",
              7000,
              "Continue drawing polygons to create a multipolygon field boundary. If you are finished, save the new boundary using control panel on the left"
            );
            this.alertShown = true;
          }
        }
      }
    },

    resetPastureInfo() {
      this.selectedOrg = null;
      this.selectedClient = null;
      this.selectedFarm = null;
      this.fieldName = null;
      this.totalAreaBySelectedUnit = null;
    },

    isEqualPolygon() {
      if (this.clickedLayer.feature.geometry) {
        var originalPolygon = this.clickedLayer.feature.geometry;
        var modifiedPolygon = this.clickedLayer.toGeoJSON().geometry;
        return booleanEqual(modifiedPolygon, originalPolygon);
      }
      return false;
    },

    isCreatedPolygonOverlapping(createdPolygon) {
      for (let boundsLayerIdx in this.boundaries) {
        var boundary = this.boundaries[boundsLayerIdx];
        var existingPolygon = polygon(boundary.geometry.coordinates[0]);
        var isIntersecting = booleanIntersects(createdPolygon, existingPolygon);
        var isOverlapping = booleanOverlap(createdPolygon, existingPolygon);

        if (isIntersecting || isOverlapping) {
          return true;
        }
      }
      return false;
    },

    isEditedPolygonOverlapping(editedPolygon) {
      const editedBbox = bbox(editedPolygon);

      const existingBboxes = this.boundaries.map((boundary) => {
        if (
          boundary.properties.field.id !== editedPolygon.properties.field.id
        ) {
          return {
            fieldId: boundary.properties.field.id,
            bbox: bbox(boundary),
          };
        }
      });
      const bboxesOverlap = (a, b) => {
        // a.minX < b.maxX && a.maxX > b.minX && a.minY < b.maxY && a.maxY > b.minY
        return a[0] <= b[2] && a[2] >= b[0] && a[1] <= b[3] && a[3] >= b[1];
      };
      const overlappingBboxes = existingBboxes.filter(
        (bbox) => bbox && bboxesOverlap(bbox.bbox, editedBbox)
      );

      const boundariesToCheck = this.boundaries.filter((boundary) =>
        overlappingBboxes
          .map((bbox) => bbox.fieldId)
          .includes(boundary.properties.field.id)
      );
      for (let boundsLayerIdx in boundariesToCheck) {
        var boundary = boundariesToCheck[boundsLayerIdx];
        if (
          boundary.properties.field.hasOwnProperty("id") &&
          editedPolygon.properties.hasOwnProperty("fieldId")
        ) {
          if (
            boundary.properties.field.id == editedPolygon.properties.fieldId
          ) {
            continue;
          }
        }
        var existingPolygon = polygon(boundary.geometry.coordinates[0]);
        if (
          editedPolygon.properties.field.id !== boundary.properties.field.id
        ) {
          var isIntersecting = booleanIntersects(
            editedPolygon,
            existingPolygon
          );
          var isOverlapping = booleanOverlap(editedPolygon, existingPolygon);

          if (isIntersecting || isOverlapping) {
            return true;
          }
        }
      }
      return false;
    },

    calculateTotalArea(featureGroup) {
      //get area for multi polygom features
      featureGroup = [];
      if (Object.keys(this.map.features)) {
        featureGroup.push();
      }
      Object.keys(this.map.features).forEach((id) => {
        featureGroup.push(this.map.features[id]);
      });

      var data = {
        features: featureGroup,
        type: "FeatureCollection",
      };

      const area = turfArea(data);

      // Restrict the area to 2 decimal points.
      const totalAreaInSquareMeters = Math.round(area * 100) / 100;
      this.totalAreaBySelectedUnit = this.convertAreaToSelectedUnit(
        Math.round(totalAreaInSquareMeters / 4047)
      );
    },

    convertAreaToSelectedUnit(totalAreaInAcres) {
      // If selected unit is in hectares, then convert the area from acres to hectares
      // Else return the area in acres
      if (this.selectedUnits == "hectares") {
        return Math.round(totalAreaInAcres * 0.4046856422 * 100) / 100;
      }
      return totalAreaInAcres;
    },

    showSnackBar(showFlag, color, displayTime, text) {
      this.snackText = text;
      this.showSnack = showFlag;
      this.timeout = displayTime;
      this.color = color;
    },

    handleInputForClient(newValue) {
      if (this.clickedLayer) {
        if (typeof newValue === "object") newValue = newValue.name;
        this.clickedLayer.toGeoJSON().properties.field.farm.client_name =
          newValue;
        if (this.clickedLayer.isNewBoundary) {
          this.map.features[
            this.clickedLayer.id
          ].properties.field.farm.client_name = newValue;
          for (let featureKey in this.map.features) {
            this.map.features[featureKey].properties.field.farm.client_name =
              newValue;
          }
        }
      }
    },

    handleBlurForClient(event) {
      if (this.clickedLayer) {
        this.clickedLayer.toGeoJSON().properties.field.farm.client_name =
          event.target.value;
        if (this.clickedLayer.isNewBoundary)
          this.map.features[
            this.clickedLayer.id
          ].properties.field.farm.client_name = event.target.value;
      }
    },

    handleInputForFarm(newValue) {
      if (this.clickedLayer) {
        if (typeof newValue === "object") newValue = newValue.name;
        this.clickedLayer.toGeoJSON().properties.field.farm.name = newValue;
        if (this.clickedLayer.isNewBoundary) {
          this.map.features[this.clickedLayer.id].properties.field.farm.name =
            newValue;
          for (let featureKey in this.map.features) {
            this.map.features[featureKey].properties.field.farm.name = newValue;
          }
        }
      }
    },

    handleBlurForFarm(event) {
      if (this.clickedLayer) {
        this.clickedLayer.toGeoJSON().properties.field.farm.name =
          event.target.value;
        if (this.clickedLayer.isNewBoundary)
          this.map.features[this.clickedLayer.id].properties.field.farm.name =
            event.target.value;
      }
    },

    handleCreatePaddock() {
      console.log("createPaddock");
      this.isCreatingSubDivide = true;
    },

    handlePaddockChange(key, value) {
       this.disablePaddockUploadChangesBtn = Object.values(this.recentlyCreatedPaddocks).some(value => value.trim() === "");
    },

    cancelChanges() {
      try {
        this.clearAllChanges()
        this.recentlyCreatedPaddocks = {}
        this.isCreatingSubDivide = false
        // this.drawnFeatures = null
        // if (Object.keys(this.map.features).length > 0) {
        //   for (const [key, obj] of Object.entries(this.map.features)) {
        //     if (obj.isNewBoundary == "true") {
        //       delete this.map.features[key]
        //     }
        //   }
        // }
      } catch (e) {
        print(e)
      }
      // this.drawnFeatures = null
    }
  },

  mounted() {
    this.fetchUneditableFields();
    let paywayScript = document.createElement("script");
    let self = this;
    paywayScript.onload = () => {
      self.updateMap();
      if (self.lastMapAction === "click") {
        self.lastMapAction = null;
        return;
      }
      self.zoomToBounds();
    };
    paywayScript.setAttribute(
      "src",
      "https://unpkg.com/leaflet.vectorgrid@1.3.0"
    );
    document.body.appendChild(paywayScript);
  },
};
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
}

.page {
  position: relative;
}

#map {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px -29px -17px -29px;
}

#modalMap {
  height: calc(40vh - 25px);
  width: calc(40vw - 25px);
  position: relative;
  background: #1b1b1d;
  margin: 10px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  position: relative;
  background: #1b1b1d;
}

.error-message {
  font-size: 15px;
  font-weight: 500;
  color: red;
}

.leafletToolTip {
  margin: 2em;
  background: rgba(24, 24, 24, 0.9);
  color: rgb(200, 186, 175);
  border: none;
  /*z-index: 1000;*/
}

.leaflet-fade-anim .leaflet-tile,
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: auto !important;
}

.legend_info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.legend_info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend_info {
  line-height: 18px;
  color: #555;
}

.legend_info i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.leaflet-top {
  z-index: 400;
}

.footer-row {
  border-top: 1px solid #e9ecef;
  margin: 0px;
}

.btn-row {
  padding: 10px 40px;
}

.snack-bar-full-map {
  margin-top: 7vh;
}

.snack-bar-half-map {
  margin-top: 7vh;
  margin-left: 53vw !important;
  width: 40vw !important;
}

.active-tab {
  color: black !important;
}

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
  .map-tiles {
    filter: var(--map-tiles-filter, none);
  }
}

.tab-header button.active {
  background-color: black;
  color: white;
}
</style>
